<template>
    <CompModal ref="comp_modal" title="授权给街镇" :on-success="onSubmit" @on-close="onClose">
        <div class="comp-org-form">
            <div class="form-item">
                <p class="title">选择行政区</p>
                <Select placeholder="选择行政区" v-model="area" transfer>
                    <Option v-for="item in area_options" :value="item.value" :key="item.value">{{ item.name }}</Option>
                </Select>
            </div>
            <div class="form-item">
                <p class="title">选择街道</p>
                <ElemCheck v-model="street" multiple search :list="street_option"></ElemCheck>
            </div>
        </div>
    </CompModal>
</template>

<script>
import CompModal from "./CompModal.vue"
import ElemCheck from "../../customform/components/ElemCheck"

import Request from "../../jointly/utils/request"
import proxy from "@/api/proxy"

export default {
    components: { CompModal, ElemCheck },

    data() {
        return {
            area: null,
            area_options: null,
            street: null,
            street_option: null,
        }
    },

    watch: {
        async area(v) {
            if (!v) return
            this.street = null
            this.street_option = await this.getOrgByCode(v)
        },
    },

    methods: {
        async display(appId) {
            this.appId = appId
            // 初始化行政区
            if (!this.area_options) {
                this.area_options = await this.getOrgByCode("4401")
            }
            // 显示窗口
            this.$refs.comp_modal.display()
        },

        async getOrgByCode(code) {
            const res = await Request.get("/gx/pc/organization/selectOrganizationByOrgCode", {
                orgCode: code,
            })

            return res.map(v => ({ value: v.orgCode, name: v.orgName }))
        },

        onSubmit() {
            const formdata = new FormData()

            this.street.forEach(item => {
                formdata.append("orgCodeList", item)
            })

            const xhr = new XMLHttpRequest()

            xhr.open("POST", `${proxy["/gateway"].target}/api/fastDev/AppForm/appAuthOrg/${this.appId}`)

            xhr.setRequestHeader("Authorization", window.sessionStorage.getItem("accessToken"))

            xhr.onloadstart = () => {
                this.$Loading.start()
            }

            xhr.onloadend = () => {
                this.$Loading.finish()
            }

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const res = JSON.parse(xhr.response)
                    if (res.code == 200) {
                        this.$Message.success("授权成功")
                        // 关闭弹窗
                        this.$refs.comp_modal.close()
                    } else this.$Message.error(res.desc || "请求出错，请稍后再试！")
                }
            }

            xhr.send(formdata)
        },

        onClose() {
            this.area = null
            this.street = null
            this.street_option = null
        },
    },
}
</script>

<style lang="less" scope>
.comp-org-form {
    .form-item {
        width: 400px;
        margin: 20px 30px;

        .title {
            margin-bottom: 5px;
            font-size: 16px;
        }
    }
}
</style>
